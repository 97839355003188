<template>
  <div class="px-5 py-20 mx-auto max-w-screen-xl lg:px-0">
    <Button />
    <div class="video-container-wrapper">
      <div class="video-container">
        <iframe
          width="300"
          height="150"
          src="https://www.youtube.com/embed/RXvikzhWyPg"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/button.vue";

export default {
  components: { Button },
};
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container-wrapper {
  max-width: 100%;
  width: 100%;
  left: 0;
  right: 0;
}
</style>
